import React, { Component } from "react";
import Files from "react-files";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

// styles
import * as styles from "./FileUpload.module.css";

const propTypes = {
  accepts: PropTypes.object,
  maxFileSize: PropTypes.number, // in bytes
  maxFiles: PropTypes.number,
};

const defaultProps = {
  accepts: ["image/png", ".pdf", "audio/*", "image/jpeg"],
  maxFileSize: 10000000,
  maxFiles: 2,
};

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      serverError: "",
    };
  }

  onFilesChange = file => {
    this.setState({ files: file });

    // when user upload a correct file size
    if (file.length !== 0) {
      const fileRemoved = false;
      this.setState({ serverError: "" });
      this.props.handleUploadFile(file[0], fileRemoved);
    } else {
      this.setState({
        serverError:
          "The file is too large to upload. The max supported file size is 20MB",
      });
    }

    // callback function -> to handle Error when user tried to upload file more than defined file size
    setTimeout(() => {
      this.props.handleUploadFileError(this.state.serverError);
    }, 500);
  };

  filesRemoveOne = file => {
    this.setState({
      // files: this.state.files.filter(item => item.id !== file.id),
      files: [],
    });
    const fileRemoved = true;
    this.props.handleUploadFile(file, fileRemoved);
  };

  render() {
    const { files } = this.state;

    return (
      <div>
        <div>
          {/* Heading */}
          <div className={styles.fileUploadSec_hdng}>
            <h3>Already have lab results? (optional)</h3>
          </div>
          {/* Sub-Heading */}
          <div className={styles.fileUploadSec_subHdng}>
            <p>Upload them here for a more personalised consultation.</p>
          </div>
          <div className="d-flex">
            {/* Upload file */}
            <div className={styles.fileUploadSec_fileSec}>
              <Files
                className="files-dropzone"
                onChange={this.onFilesChange}
                accepts={this.props.accepts}
                multiple={false}
                maxFiles={this.props.maxFiles}
                maxFileSize={this.props.maxFileSize}
                minFileSize={0}
                clickable
              >
                {/* upload icon */}
                <div className={styles.fileUploadSec_fileSec_icn}>
                  <span
                    className={`mediumIcon icon-10`}
                    aria-hidden="true"
                  ></span>
                </div>
                {/* text */}
                <div className={styles.fileUploadSec_fileSec_icnTxt}>
                  <p>add PDF (max size 20 MB)</p>
                </div>
              </Files>
            </div>
            {/* Preview of image */}
            {files.length > 0 ? (
              <div className={`${styles.fileUploadSec_fileSec_uploadedFile}`}>
                <ul className="d-flex">
                  {files.map(file => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <img
                            className={`files-list-item-preview-image ${styles.fileUploadSec_img}`}
                            src={file.preview.url}
                            alt=""
                          />
                        ) : (
                          <div className="files-list-item-preview-extension">
                            <div className={styles.fileUploadSec_uploadedImg}>
                              <StaticImage
                                src="../../images/doctor/pdf-logo.png"
                                alt=""
                                className="img-fluid"
                                placeholder="blurred"
                                quality={90}
                                style={{ width: "30px" }}
                              />
                            </div>
                            <div
                              className={styles.fileUploadSec_uploadedImg_txt}
                            >
                              {file.name}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        id={file.id}
                        className="files-list-item-remove"
                        onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                        role="presentation"
                      >
                        <span
                          className={`icon-cross`}
                          aria-hidden="true"
                          style={{ color: "#29817A", cursor: "pointer" }}
                        ></span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export default FileUpload;

// HOW TO USE:-
// <FileUpload />
// <FileUpload accepts={["image/png", ".pdf", "audio/*"]} maxFileSize={10000000} />
