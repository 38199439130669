import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

//date-fns for datetime manipulation
import { format, parse } from "date-fns";

// styles
import * as styles from "./DateTime.module.css";

// How to use at bottom

const DateTime = props => {
  const [currentAvailableSlot, setCurrentAvailableSlot] = useState([]);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlotValue, setSelectedSlotValue] = useState(null); // slot time selected by user using radio button

  useEffect(() => {
    if (props.availableSlot && props.availableSlot.length > 0) {
      setCurrentAvailableSlot(props.availableSlot);
      setSelectedDate(
        format(
          parse(props.availableSlot[0].date, "yyyy-MM-dd", new Date()),
          "dd MMMM"
        )
      );
    }
  }, [props.availableSlot]);

  const handleSelectDateSlot = (item, index) => {
    if (item.availableSlot.length !== 0) {
      setCurrentAvailableSlot(item.availableSlot);
      setSelectedDateIndex(index);
      setSelectedDate(
        format(parse(item.date, "dd-MM-yyyy", new Date()), "dd MMMM")
      );
      setSelectedStartTime(null);
      setSelectedEndTime(null);
      setSelectedTimeIndex(null);
    } else {
      setCurrentAvailableSlot(item.availableSlot);
      setSelectedDateIndex(index);
    }
  };

  const handleSelectTimingSlot = (index, value) => {
    setSelectedTimeIndex(index);
    setSelectedStartTime(value.startTime);
    setSelectedEndTime(value.endTime);
    setSelectedSlotValue(value);
  };

  // To show selected slot(date + time) by user on UI
  const handleSlotSelected = () => {
    if (selectedSlotValue) {
      // callback function
      const selectSlotToSend =
        format(
          parse(selectedSlotValue.date, "yyyy-MM-dd", new Date()),
          "dd MMM, EEEE"
        ) +
        ", " +
        selectedSlotValue.startTime; // 23 Nov, Tuesday, 09:15 AM
      props.handleDoctorSlotTime(selectedSlotValue.time, selectSlotToSend);
    }
  };

  return (
    <>
      {props.windowSize && props.windowSize.width <= 768 ? (
        <Offcanvas
          show={props.showCanvasModal}
          placement="bottom"
          style={{ height: "100%" }}
        >
          <Offcanvas.Header>
            {/* heading */}
            <div className={styles.dateTimeSec_hdng}>
              <h3>{props.title}</h3>
            </div>
            <div onClick={props.onHideCanvasModal} role="presentation">
              <span
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "#9C9C9C",
                  border: "1px solid #E6E6E6",
                  borderRadius: "22px",
                }}
                className="icon-cross"
                aria-hidden="true"
              ></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div>
              {/* Month */}
              <div className={styles.dateTimeSec_month}>
                <h3>{props.showMonth}</h3>
              </div>
              {/* days */}
              <div className={styles.dateTimeSec_days}>
                {props.dateRange.map((item, index) => (
                  <div
                    className={
                      selectedDateIndex === index
                        ? styles.dateTimeSec_days_sec
                        : styles.dateTimeSec_days_notSelect
                    }
                    role="presentation"
                    onClick={() => {
                      handleSelectDateSlot(item, index);
                    }}
                  >
                    <p className={styles.dateTimeSec_days_firstPara}>
                      {item.myDate}
                    </p>
                    <p className={styles.dateTimeSec_days_scndPara}>
                      {item.day}
                    </p>
                  </div>
                ))}
              </div>
              <div>
                {/* time slots */}
                <div>
                  {currentAvailableSlot && currentAvailableSlot.length > 0 ? (
                    <div>
                      {currentAvailableSlot.map((item, index) => (
                        <div>
                          <div className={styles.dateTimeSec_inpSec}>
                            <label
                              className={`custRadio custom-control-label`}
                              htmlFor={"customRadio" + index}
                            >
                              <input
                                type="radio"
                                id={"customRadio" + index}
                                name={"customRadio"}
                                checked={selectedTimeIndex === index}
                                onChange={e =>
                                  handleSelectTimingSlot(
                                    index,
                                    item,
                                    e.target.checked
                                  )
                                }
                                className="custom-control-input"
                              />
                              <p>
                                {item.startTime} to {item.endTime}
                              </p>
                              <span className="custRadioCheckround3"></span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.dateTimeSec_slotFullSec}>
                      <p>
                        Sorry, all the slots are now full, please select a
                        different date to proceed
                      </p>
                    </div>
                  )}
                </div>
                {/* cta */}
                {currentAvailableSlot && currentAvailableSlot.length > 0 ? (
                  <div
                    className={styles.dateTimeSec_ctaSec}
                    onClick={handleSlotSelected}
                    role="presentation"
                  >
                    <button onClick={props.onHideCanvasModal}>
                      <span>
                        {/* <p>14 August, 3 PM to 4 PM</p> */}
                        {selectedDate &&
                        selectedStartTime &&
                        selectedEndTime &&
                        currentAvailableSlot.length > 0 ? (
                          <span>
                            <p>
                              {selectedDate +
                                ", " +
                                selectedStartTime +
                                " to " +
                                selectedEndTime}
                            </p>
                            <p>Confirm date and time</p>
                          </span>
                        ) : (
                          <div>
                            <p>Confirm date and time</p>
                          </div>
                        )}
                      </span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas show={props.showCanvasModal} placement="end">
          <Offcanvas.Header>
            <div onClick={props.onHideCanvasModal} role="presentation">
              <span
                style={{ fontSize: "24px", cursor: "pointer" }}
                className="icon-cross"
                aria-hidden="true"
              ></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div>
              {/* heading */}
              <div className={styles.dateTimeSec_hdng}>
                <h3>{props.title}</h3>
              </div>
              {/* Month */}
              <div className={styles.dateTimeSec_month}>
                <h3>{props.showMonth}</h3>
              </div>
              {/* days */}
              <div className={styles.dateTimeSec_days}>
                {props.dateRange.map((item, index) => (
                  <div
                    className={
                      selectedDateIndex === index
                        ? styles.dateTimeSec_days_sec
                        : styles.dateTimeSec_days_notSelect
                    }
                    role="presentation"
                    onClick={() => {
                      handleSelectDateSlot(item, index);
                    }}
                  >
                    <p className={styles.dateTimeSec_days_firstPara}>
                      {item.myDate}
                    </p>
                    <p className={styles.dateTimeSec_days_scndPara}>
                      {item.day}
                    </p>
                  </div>
                ))}
              </div>
              <div>
                {/* time slots */}
                <div>
                  {currentAvailableSlot && currentAvailableSlot.length > 0 ? (
                    <div>
                      {currentAvailableSlot.map((item, index) => (
                        <div>
                          <div className={styles.dateTimeSec_inpSec}>
                            <label
                              className={`custRadio custom-control-label`}
                              htmlFor={"customRadio" + index}
                            >
                              <input
                                type="radio"
                                id={"customRadio" + index}
                                name={"customRadio"}
                                checked={selectedTimeIndex === index}
                                onChange={e =>
                                  handleSelectTimingSlot(
                                    index,
                                    item,
                                    e.target.checked
                                  )
                                }
                                className="custom-control-input"
                              />
                              <p>
                                {item.startTime} to {item.endTime}
                              </p>
                              <span className="custRadioCheckround3"></span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.dateTimeSec_slotFullSec}>
                      <p>
                        Sorry, all the slots are now full, please select a
                        different date to proceed
                      </p>
                    </div>
                  )}
                </div>
                {/* cta */}
                <div
                  className={styles.dateTimeSec_ctaSec}
                  onClick={handleSlotSelected}
                  role="presentation"
                >
                  <button onClick={props.onHideCanvasModal}>
                    <span>
                      {/* <p>14 August, 3 PM to 4 PM</p> */}
                      {selectedDate &&
                      selectedStartTime &&
                      selectedEndTime &&
                      currentAvailableSlot.length > 0 ? (
                        <span>
                          <p>
                            {selectedDate +
                              ", " +
                              selectedStartTime +
                              " to " +
                              selectedEndTime}
                          </p>
                          <p>Confirm date and time</p>
                        </span>
                      ) : (
                        <div>
                          <p>Confirm date and time</p>
                        </div>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default DateTime;

// How to Use
/* <DateTimeModal
showCanvasModal={showDateTimeModal}
onHideCanvasModal={() => setShowDateTimeModal(false)}
title="When would you like to book consultation?"
showMonth={showMonth}
dateRange={dateRange}
selectedDateIndex={selectedDateIndex}
selectedTiming={selectedTiming}
selectedDate={selectedDate}
availableSlot={availableSlot}
windowSize={windowSize}
/>; */

// Format of the props should be like this
// showMonth = August 2021
// dateRange = [
//   [
//     {
//       date: "19-11-2021",
//       day: "Fri",
//       myDate: "19",
//       availableSlot: [],
//     },
//   ],
//   [
//     {
//       date: "20-11-2021",
//       day: "Sat",
//       myDate: "20",
//       availableSlot: [],
//     },
//   ],
// ];
// selectedDateIndex={0}
// selectedTiming = dateRange[0].availableSlot[0]
// selectedDate = "dd-MM-yyyy"
// availableSlot = [
//   {
//    date: "2021-11-19"
//    endTime: "01:20 PM"​​
//    slotsAvailable: 1​​
//    startTime: "01:00 PM"​​
//    time: "2021-11-19T13:00:00+0530"
//   },
//   {
//     date: "2021-11-19"
//     endTime: "01:20 PM"​​
//     slotsAvailable: 1​​
//     startTime: "01:00 PM"​​
//     time: "2021-11-19T13:00:00+0530"
//   }]
