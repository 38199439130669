import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
// import Loader from "react-loader-spinner";

// config
import config from "../../../config";

// css
// import * as style from "./webinar.module.css";

// gpay codes
const GPAY = {
  amount: 301,
  code: "GPAY",
};

const CouponFormDoctor = props => {
  const [loading, setLoading] = useState(false);

  const [serverError, setServerError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [opacity, setOpacity] = useState(0.2);

  const handleOpacity = val => {
    setOpacity(val);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async data => {
    const options = {
      method: "get",
      url:
        config.DX_API_BASE_URL +
        "/api/marketing/coupons/" +
        data.coupon.toUpperCase() +
        "?type=doctor&pId=" +
        (props.pId ? props.pId : props.serviceId),
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
    };

    try {
      setLoading(true);
      setServerError("");
      let res = await axios(options);
      res = res.data.results;
      setLoading(false);
      if (res.length === 0) {
        // setServerError("Invalid promo code");

        // applied the gpay coupon code
        setSuccessMessage("Applied");
        props.handleSuccessMsg("Applied");
        props.handleCouponUpdate(GPAY.amount, GPAY.code, data.coupon);
        props.handleRemoveAppliedCoupon();
      } else if (res[0]["status"] !== "Active") {
        setServerError("Invalid promo code");
      } else {
        const price = res[0]["discountedAmount"] || "";
        let msg = "Applied";
        // if (props.coupon === data.coupon) {
        //   msg = "Invalid promo code";
        // }

        setSuccessMessage(msg);
        props.handleSuccessMsg(msg);
        props.handleCouponUpdate(price, data.coupon, data.coupon);
        props.handleRemoveAppliedCoupon();
      }
    } catch (error) {
      setLoading(false);
      setServerError("Try again");
      console.error("coupon code server error", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
      {successMessage === "" ? (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <input
                onClick={() => handleOpacity(1)}
                className={`form-control ${errors.coupon ? "is-invalid" : ""}`}
                style={{
                  height: "calc(1.5em + .75rem + 12px)",
                  borderRadius: "50px",
                }}
                placeholder="Enter coupon code"
                id="coupon"
                type="text"
                {...register("coupon", {
                  required: "Enter promo code",
                  pattern: {
                    value: /^[A-Za-z0-9]+$/,
                    message: "Please enter your valid coupon code",
                    minLength: {
                      value: 6,
                      message: "Coupon code is too small",
                    },
                    maxLength: {
                      value: 50,
                      message: "Coupon code is too long",
                    },
                  },
                })}
              />
              {errors.coupon && (
                <span className="invalid-feedback">
                  {errors.coupon.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-12 text-center">
            <div>
              <button
                type="submit"
                className={`input_apply_btn ${loading}`}
                style={{
                  width: "auto",
                  position: "absolute",
                  right: "0",
                  background: "none",
                  border: "none",
                  padding: "0",
                  cursor: "pointer",
                  marginTop: "-37px",
                  marginRight: "35px",
                  color: "#3E928B",
                  opacity: opacity,
                }}
              >
                <div style={{ position: "relative" }}>
                  <span className={`${loading}`}>
                    <span>Apply</span>
                  </span>
                  {/* <span>
                    <Loader
                      type="ThreeDots"
                      color="#FFF"
                      height={30}
                      width={30}
                      visible={loading}
                    />
                  </span> */}
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-danger">{serverError}</div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <input
                className={
                  errors.coupon ? "form-control is-invalid" : "form-control"
                }
                style={{
                  border: "1px solid #00A651",
                  height: "calc(1.5em + .75rem + 12px)",
                }}
                placeholder="Enter coupon code"
                id="coupon"
                type="text"
                {...register("coupon", {
                  required: "Enter promo code",
                  pattern: {
                    value: /^[A-Za-z0-9]+$/,
                    message: "Please enter your valid coupon code",
                  },
                  minLength: { value: 6, message: "Coupon code is too small" },
                  maxLength: { value: 50, message: "Coupon code is too long" },
                })}
              />
              {errors.coupon && (
                <span className="invalid-feedback">
                  {errors.coupon.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-12 text-center">
            <div>
              <button
                type="submit"
                className={`input_apply_btn ${loading}`}
                style={{
                  width: "auto",
                  position: "absolute",
                  right: "0",
                  background: "#29817A",
                  border: "none",
                  padding: "0",
                  cursor: "pointer",
                  marginTop: "-52px",
                  marginRight: "35px",
                  color: "#FFFFFF",
                }}
              >
                <div style={{ position: "relative" }}>
                  <span className={`${loading}`}>
                    <span
                      style={{
                        position: "absolute",
                        color: "#29817A",
                        right: "0",
                        top: "15px",
                      }}
                    >
                      {successMessage}
                    </span>
                  </span>
                  {/* <span>
                    <Loader
                      type="ThreeDots"
                      color="#FFF"
                      height={30}
                      width={30}
                      visible={loading}
                    />
                  </span> */}
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-danger">{serverError}</div>
          </div>
        </div>
      )}
    </form>
  );
};

export default CouponFormDoctor;
