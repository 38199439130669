import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Confetti from "react-confetti";
import Loader from "react-loader-spinner";
import { navigate } from "gatsby";
import { parse, format } from "date-fns";
import { StaticImage } from "gatsby-plugin-image";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// services
// import { sendToCT } from "../services/Clevertap";

// email
import { sendEmailNotifecation } from "../services/SendEmail";

// LocalStorage
// import LS from "../utils/LocalStorageService";

// hook
import { useDoctorDetailQuery } from "../hooks/useDoctorDetailQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// components
import CouponModal from "../components/DoctorConsult/Coupon/CouponModal";
import DateTimeModal from "../components/DateTime/DateTimeModal";
import DoctorDetails from "../components/DoctorConsult/DoctorDetails/DoctorDetails";
import FileUpload from "../components/FileUpload/FileUpload";
import FloatingCta from "../components/DoctorConsult/FloatingCta/FloatingCta";

// styles
import * as styles from "./doctor-consult.module.css";

let windowWidth, windowHeight;

if (typeof window !== `undefined`) {
  windowWidth = parseInt(window.innerWidth);
  windowHeight = parseInt(window.innerHeight);

  if (windowWidth <= 768) {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "900px";
  } else {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "500px";
  }
}

const DoctorSchedulingPage = props => {
  const [doctorDetail, setDoctorDetail] = useState({});
  const [sellingPrice, setSellingPrice] = useState(null);
  const [costPrice, setCostPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  // states for file upload
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedText, setUploadedText] = useState("");

  // states defined for coupon
  const [showCouponSection] = useState(false); // Handle -> Show Coupon Section Or Not -> true/false
  const [couponList, setCouponList] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [coupanEnabled, setCoupanEnabled] = useState(false);
  const [couponPlaceholder, setCouponPlaceholder] = useState("");
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  // states defined for date time modal
  const [showDateTimeModal, setShowDateTimeModal] = useState(false);
  const [showMonth, setShowMonth] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [selectedDoctorSlotTime, setSelectedDoctorSlotTime] = useState(""); // selected slot time for payment api(format:-2021-11-23T09:00:00+0530)
  const [showSlotTime, setShowSlotTime] = useState(""); // show selected time slot on i/p box(format:- 23 Nov, Tuesday, 09:15 AM)
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTiming, setSelectedTiming] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlot, setAvailableSlot] = useState([]);

  const serverErrorRef = useRef(null); // Scroll to bottom when server error comes.

  const { id, name, email, phone, appHeader, twinApp, clientId } =
    queryString.parse(props.location.search);

  const doctorNode = useDoctorDetailQuery();
  const windowSize = useWindowSize();

  const productId = doctorNode[0].productId;

  // ------> GET DOCTOR DETAILS <------
  useEffect(() => {
    const getDoctorDetails = async () => {
      const doctorDetailOptions = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/doctor/" + id,
      };
      try {
        setLoading(true);
        const response = await axios(doctorDetailOptions);
        setDoctorDetail(response.data.data);
        setCostPrice(response.data.data.costPrice);
        setLoading(false);
      } catch (error) {
        console.error("Error in fetching doctors details", error);
        // setServerError("Something went wrong");
      }
    };
    getDoctorDetails();
  }, [id]);

  // --------> GET CALENDAR DETAILS OF DOCTOR <---------
  useEffect(() => {
    const getDoctorSchedules = async () => {
      const scheduleOptions = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/doctor/" + id + "/cal",
      };
      try {
        const response = await axios(scheduleOptions);
        if (response.status === 200) {
          let dateArr = [];
          setShowMonth(
            format(
              parse(response.data[0].date, "yyyy-MM-dd", new Date()),
              "MMMM yyyy"
            )
          );
          response.data.forEach(item => {
            let availableSlot = [];
            // console.log("item...", item);
            item.slots.forEach(slot => {
              // console.log("slot", slot);
              if (slot.slotsAvailable === 1) {
                availableSlot.push(slot);
              }
            });
            dateArr.push({
              myDate: format(parse(item.date, "yyyy-MM-dd", new Date()), "dd"),
              day: format(parse(item.date, "yyyy-MM-dd", new Date()), "EEE"),
              date: format(
                parse(item.date, "yyyy-MM-dd", new Date()),
                "dd-MM-yyyy"
              ),
              availableSlot: availableSlot,
            });
          });
          setDateRange(dateArr);
          setAvailableSlot(dateArr[0].availableSlot);
          setSelectedDate(dateArr[0].date);
          setSelectedDateIndex(0);
          setSelectedTiming(dateArr[0].availableSlot[0]);
        }
      } catch (error) {
        console.error("Error in fetching doctors details", error);
      }
    };
    getDoctorSchedules();
  }, [id]);

  // -------> HANDLE COUPON UPDATE <--------
  const handleCouponUpdate = (price, couponVal, placeholder) => {
    const newSellingPrice = parseInt(costPrice) - parseInt(price);
    setCoupon(couponVal);
    setCouponPlaceholder(placeholder);
    setSellingPrice(newSellingPrice);
    setCoupanEnabled(true);
  };

  // ------> GET LIST OF COUPONS <-------
  useEffect(() => {
    const getListOfCoupons = async () => {
      const couponOptions = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          `/api/marketing/coupons/?size=3&pId=${productId}&type=doctor`,
      };

      if (costPrice) {
        try {
          setLoading(true);
          const response = await axios(couponOptions);
          let resultData = response.data.results.filter(obj => {
            return obj.priority === 1;
          });
          if (resultData.length !== 0) {
            const newSellingPrice =
              parseInt(costPrice) - parseInt(resultData[0].discountedAmount);
            setCoupon(resultData[0].couponCode);
            setCouponPlaceholder(resultData[0].couponCode);
            setCouponList(response.data.results);
            setSellingPrice(newSellingPrice);
            setCoupanEnabled(true);
            setLoading(false);
          } else {
            setCouponList(response.data.results);
            setSellingPrice(parseInt(costPrice));
            setCoupanEnabled(false);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error in fetching doctors details", error);
          // setServerError("Something went wrong while fetching coupon details");
        }
      }
    };
    getListOfCoupons();
  }, [costPrice, productId]);

  // ======== UPLOAD PDF FILE ======== //
  const handleUploadFile = async (val, val1) => {
    if (val) {
      const formData = new FormData();
      formData.append("uploadFile", val, val.name);

      const uploadOptions = {
        method: "post",
        url: config.DX_API_BASE_URL + "/api/drives/15",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      };

      try {
        if (val1) {
          setUploadedText(null);
        } else {
          setServerError(null);
          setUploadedText("Uploading...");
          const response = await axios(uploadOptions);
          setUploadedFile(response.data.file);
          setUploadedText("File has been successfully uploaded");
        }
      } catch (error) {
        console.log("errorr in uploading file", error);
      }
    }
  };

  // handle error - when user tries to upload file more than defined size
  const handleUploadFileError = val => {
    if (val) setServerError(val);
  };

  // ======= START - PAYMENT ======== //

  const getCallbackUrl = oId => {
    // const headerValue = LS.getValue("header");

    if (
      (appHeader && appHeader === "mobile") ||
      (appHeader && appHeader === "no")
    ) {
      const url =
        `/doctor-consult-successful/?clientId=${clientId}&appHeader=${appHeader}&orderId=` +
        oId +
        (twinApp && twinApp === "yes" ? "&twinApp=yes" : "");
      return url;
    } else {
      const url =
        "/doctor-consult-successful/?orderId=" +
        oId +
        (twinApp && twinApp === "yes" ? "&twinApp=yes" : "");
      return url;
    }
  };

  // -----> Razorpay Function <------
  const handleRazorpayPayment = async (orderId, oId) => {
    const callbackUrl = getCallbackUrl(oId);

    if (orderId) {
      let options = {
        key: config.RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: coupanEnabled ? "" + sellingPrice : "" + costPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Twins Digital Services India P Ltd",
        description: "Webinar payment",
        image:
          "https://res.cloudinary.com/diokr9soz/image/upload/v1624622930/app-images/logo-gray_skss8a.svg",
        order_id: orderId,
        handler: function (response) {
          if (window.twinApp) {
            let data = JSON.stringify({ action: "close_pop_up_window" });
            window.twinApp.postMessage(data);
          }

          navigate(callbackUrl);
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        modal: {
          escape: false,
        },
        readonly: {
          contact: true,
          email: true,
        },
        theme: {
          color: "#F37254",
        },
      };

      let rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        if (window.twinApp) {
          let data = JSON.stringify({ action: "close_pop_up_window" });
          window.twinApp.postMessage(data);
        }

        // Send Slack Notification On Payment Failed
        if (response.error) {
          sendEmailNotifecation(
            window.location.href,
            options,
            response.error || {},
            "/doctor-consult/"
          );
        }

        console.error("Payment Failed, Try Again !", response.error);
        console.log("error.code", response.error.code);
        console.log("error.description", response.error.description);
        console.log("error.source", response.error.source);
        console.log("error.step", response.error.step);
        console.log("error.reason", response.error.reason);
        console.log("error.order_id", response.error.metadata.order_id);
        console.log("error.payment_id", response.error.metadata.payment_id);
      });
      rzp1.open();
    }
  };

  // function to update timings and date for payment and success page
  const handleDoctorSlotTime = (val1, val2) => {
    setSelectedDoctorSlotTime(val1);
    setShowSlotTime(val2);
    setServerError(null);
  };

  // -----> Book Appointment Function <-----
  const handleBookDoctorAppointment = async () => {
    if (!selectedDoctorSlotTime) {
      setServerError("Slot time cannot be left empty");
    } else {
      let appointmentBody = {
        clientId: "",
        firstName: name,
        lastName: "",
        phone: phone,
        email: email,
        productId: productId,
        amount: coupanEnabled ? "" + sellingPrice : "" + costPrice,
        sourceType: "PROSPECT_MARKETING_SITE",
        couponEnabled: coupanEnabled,
        couponCode: coupon,
        doctorId: id,
        calendarId: doctorDetail.calendarId,
        slotTime: selectedDoctorSlotTime,
        attachment: uploadedFile,
      };

      let doctorAppointmentOptions = {
        method: "post",
        url: config.DX_API_BASE_URL + "/api/orders/bookWebinar",
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
          "Content-Type": "application/json",
        },
        data: appointmentBody,
      };

      try {
        setPayLoading(true);
        setServerError(null);
        const response = await axios(doctorAppointmentOptions);
        setPayLoading(false);

        // clevertap event
        // if (twinApp && twinApp === "yes") {
        //   // For Twin App
        //   if (window.twinApp) {
        //     const ctData = JSON.stringify({
        //       action: "cleverTapEvent",
        //       arguments: {
        //         event: "doctor_consult_payment_initiated",
        //         payload: {
        //           action: JSON.stringify({
        //             msg: "Doctor_Consult_SchedulingPage_BuyNow_Cta_Clicked",
        //             doctorId: id,
        //             name: name,
        //             email: email,
        //             phone: phone,
        //             productId: productId,
        //             couponEnabled: coupanEnabled,
        //             couponCode: coupon,
        //             slotTime: selectedDoctorSlotTime,
        //             calendarId: doctorDetail.calendarId,
        //           }),
        //         },
        //       },
        //     });
        //     window.twinApp.postMessage(ctData);
        //   }
        // } else {
        //   // For Web
        //   sendToCT("doctor_consult_payment_initiated", {
        //     action: "Doctor_Consult_SchedulingPage_BuyNow_Cta_Clicked",
        //     doctorId: id,
        //     name: name,
        //     email: email,
        //     phone: phone,
        //     productId: productId,
        //     couponEnabled: coupanEnabled,
        //     couponCode: coupon,
        //     slotTime: selectedDoctorSlotTime,
        //     calendarId: doctorDetail.calendarId,
        //   });
        // }

        // razorpay
        handleRazorpayPayment(
          response.data.results.providerOrderId,
          response.data.results.id
        );
      } catch (error) {
        setPayLoading(true);
        console.error("Error while doing booking doctor appointment", error);
        setPayLoading(false);
        setServerError(
          "There seems to be an issue at our end. Please call +91 9005008946 to complete the payment"
        );
      }
    }
  };

  // ======= END - PAYMENT ======== //

  // ======= SCROLL TO BOTTOM(ERROR DIV SECTION) WHEN SERVER ERROR COMES ========= //
  useEffect(() => {
    if (serverError) {
      const scrollToBottom = () => {
        serverErrorRef.current.scrollIntoView({ behavior: "smooth" });
      };

      scrollToBottom();
    }
  }, [serverError]);

  return (
    <>
      <div className={`bgLightGreen ${styles.doctorConsult_schedulePage}`}>
        {showConfetti === true ? (
          <Confetti width={windowWidth} height={windowHeight} />
        ) : (
          ""
        )}
        <Seo
          title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
          description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
        />

        {appHeader === "mobile" && (
          <AppHeader text="DOCTOR CONSULT" isBack={true} />
        )}

        <FloatingCta
          sellingPrice={sellingPrice}
          costPrice={costPrice}
          paymentAmount={true}
          handleBookDoctorAppointment={handleBookDoctorAppointment}
          payLoading={payLoading}
          coupanEnabled={coupanEnabled}
        />
        <DoctorDetails
          doctorDetail={doctorDetail}
          loading={loading}
          windowSize={windowSize}
          appHeader={appHeader}
          twinApp={twinApp}
          clientId={clientId}
        />
        <div className={`container twinContainer ${styles.docSched_schedSec}`}>
          <div className={styles.docSched_fileUpld_mobLine_1}></div>
          <div className="row">
            {/* Calendar */}
            <div className="col-12 col-xs-12 col-md-6 col-lg-6">
              {/* heading */}
              <div className={styles.docSched_dateTimeSec_hdng}>
                <h3>What time works best?</h3>
              </div>
              {/* Sub-Heading */}
              <div className={styles.docSched_dateTimeSec_subHdng}>
                <p>Schedule your preferred date and time.</p>
              </div>
              {/* choose calendar */}
              <div
                className={styles.docSched_dateTimeSec_bttmSec}
                onClick={() => setShowDateTimeModal(true)}
                role="presentation"
              >
                {/* icon */}
                <div className={styles.docSched_dateTimeSec_bttmSec_icn}>
                  <span className={`icon-Date`} aria-hidden="true"></span>
                </div>
                {/* text */}
                <div className={styles.docSched_dateTimeSec_bttmSec_txt}>
                  {showSlotTime ? (
                    <div>
                      <p>{showSlotTime}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Select date & time</p>
                    </div>
                  )}
                </div>
              </div>

              {/* Coupon Section */}
              {showCouponSection ? (
                <div>
                  {coupanEnabled ? (
                    <div>
                      <div className={styles.docSched_cpnSec_hdngTxt}>
                        <h3>Coupon code applied</h3>
                      </div>
                      <div
                        className={`d-flex align-items-center ${styles.docSched_cpnSec_inpBox}`}
                      >
                        {/* icon */}
                        <div className={styles.docSched_cpnSec_inpBox_icn}>
                          <span
                            className={`icon-yes`}
                            aria-hidden="true"
                          ></span>
                        </div>
                        {/* coupon text */}
                        <div className={styles.docSched_cpnSec_inpBox_cpnTxt}>
                          <p>{couponPlaceholder}</p>
                        </div>
                        {/* change text */}
                        <div className={styles.docSched_cpnSec_inpBox_chngTxt}>
                          <p
                            role="presentation"
                            onClick={() => setShowCouponModal(true)}
                          >
                            Edit
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.docSched_cpnSec_hdngTxt}>
                        <h3>Coupon Code (If any)</h3>
                      </div>
                      <div
                        className={styles.docSched_cpnSec_ntApplySec}
                        role="presentation"
                        onClick={() => setShowCouponModal(true)}
                      >
                        <p>Apply Coupon Code</p>
                        <span
                          className={`icon-coupon ${styles.docSched_cpnSec_icn}`}
                        ></span>
                      </div>
                    </div>
                  )}
                  {/* Mobile line */}
                  <div className={styles.docSched_fileUpld_mobLine_2}></div>
                </div>
              ) : null}
            </div>
            {/* File Upload Section */}
            <div className="col-12 col-xs-12 col-md-6 col-lg-6">
              <div className={styles.docSched_fileUpldSec}>
                <FileUpload
                  handleUploadFile={handleUploadFile}
                  handleUploadFileError={handleUploadFileError}
                  accepts={[".pdf"]}
                  maxFileSize={20000000}
                  maxFiles={2}
                />
                {/* Mobile line */}
                <div className={styles.docSched_fileUpld_mobLine_3}></div>
              </div>
            </div>
            {/* File Uploading status */}
            {uploadedText ? (
              <div>
                <p style={{ color: "#29817a", textAlign: "center" }}>
                  {uploadedText}
                </p>
              </div>
            ) : null}
            <div
              className={`text-center text-danger d-block pt-3 text-align-center pe-4 ${styles.docSched_serverError}`}
              ref={serverErrorRef}
            >
              {serverError} &nbsp;
            </div>
            {/* For Desktop Only - Book Cta */}
            <div className={styles.docSched_ctaSec}>
              {/* upper text */}
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "56px" }}
              >
                <div className="position-relative">
                  <div>
                    <StaticImage
                      src="../images/doctor/Star.png"
                      alt=""
                      className="img-fluid"
                      placeholder="blurred"
                      quality={90}
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                  <div className="position-absolute" style={{ top: "0" }}>
                    <span
                      className={`icon-yes`}
                      aria-hidden="true"
                      style={{ fontSize: "28px", color: "#FFFFFF" }}
                    ></span>
                  </div>
                </div>
                <div className={styles.docSched_ctaSec_frstTxt}>
                  <p>100% MONEY BACK GUARANTEE</p>
                </div>
              </div>
              {/* button */}
              <div className={`mt-2 ${styles.docSched_ctaSec_btn}`}>
                <button onClick={handleBookDoctorAppointment}>
                  {coupanEnabled ? (
                    <div className="position-relative">
                      <span>Book now @</span>
                      <span className={styles.docSched_ctaSec_btn_actPrce}>
                        ₹{costPrice}
                      </span>{" "}
                      {loading ? (
                        <span
                          className="position-absolute"
                          style={{ right: "25%", top: "-4px" }}
                        >
                          <Loader
                            type="ThreeDots"
                            color="#FFF"
                            height={30}
                            width={30}
                            visible={loading}
                          />
                        </span>
                      ) : (
                        <span>₹{sellingPrice}</span>
                      )}
                      <span
                        className="icon-arrow-forward ps-2"
                        aria-hidden="true"
                      ></span>
                      <span
                        className="position-absolute"
                        style={{ left: "50%", top: "-3px" }}
                      >
                        <Loader
                          type="ThreeDots"
                          color="#29817a"
                          height={30}
                          width={30}
                          visible={payLoading}
                        />
                      </span>
                    </div>
                  ) : (
                    <div className="position-relative">
                      <span>Book now @</span>
                      {loading ? (
                        <span
                          className="position-absolute"
                          style={{ right: "25%", top: "-4px" }}
                        >
                          <Loader
                            type="ThreeDots"
                            color="#FFF"
                            height={30}
                            width={30}
                            visible={loading}
                          />
                        </span>
                      ) : (
                        <span className="ps-2">₹{costPrice}</span>
                      )}
                      <span
                        className="icon-arrow-forward ps-2"
                        aria-hidden="true"
                      ></span>
                      <span
                        className="position-absolute"
                        style={{ left: "50%", top: "-3px" }}
                      >
                        <Loader
                          type="ThreeDots"
                          color="#29817a"
                          height={30}
                          width={30}
                          visible={payLoading}
                        />
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal - Coupon */}
        {showCouponModal && (
          <CouponModal
            showCanvasModal={showCouponModal}
            onHideCanvasModal={() => setShowCouponModal(false)}
            price={sellingPrice}
            pId={productId}
            serviceId={productId}
            handleCouponUpdate={handleCouponUpdate}
            handleSuccessMsg={msg => {
              // setsuccessMsg(msg);
              setShowConfetti(true);
              setTimeout(() => setShowCouponModal(false), 600);
              setTimeout(() => setShowConfetti(false), 4500);
            }}
            coupon={coupon}
            couponList={couponList}
            windowSize={windowSize}
          />
        )}
        {/* Modal - Date Time */}
        <DateTimeModal
          showCanvasModal={showDateTimeModal}
          onHideCanvasModal={() => setShowDateTimeModal(false)}
          title="What time works best?"
          handleDoctorSlotTime={handleDoctorSlotTime}
          showMonth={showMonth}
          dateRange={dateRange}
          selectedDateIndex={selectedDateIndex}
          selectedTiming={selectedTiming}
          selectedDate={selectedDate}
          availableSlot={availableSlot}
          windowSize={windowSize}
        />
      </div>
    </>
  );
};

export default DoctorSchedulingPage;
