import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

// components
import CouponForm from "./CouponForm";

// css
import * as styles from "./Coupon.module.css";

const DoctorCouponModal = props => {
  const [couponApplied, setCouponApplied] = useState(false);
  const [index, setIndex] = useState(null);

  const updateCouponStatus = (cpn, amt, i) => {
    if (cpn) {
      props.handleCouponUpdate(amt, cpn, cpn);
      props.handleSuccessMsg("Applied");
      setCouponApplied(true);
      setIndex(i);
    }
  };

  const handleRemoveAppliedCoupon = () => {
    setCouponApplied(false);
  };

  useEffect(() => {
    if (props.coupon && props.couponList) {
      const data = props.couponList.findIndex(
        ele => ele.couponCode === props.coupon
      );
      setCouponApplied(true);
      setIndex(data);
    }
  }, [props.couponList, props.coupon]);

  return (
    <>
      {props.windowSize && props.windowSize.width <= 768 ? (
        <Offcanvas
          show={props.showCanvasModal}
          placement="bottom"
          style={{ height: "91%" }}
        >
          <Offcanvas.Header className={styles.cpnModal_modalMob_header}>
            <div
              onClick={props.onHideCanvasModal}
              className={styles.cpnModal_modalMob_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div className={`mt-4 ${styles.cpnModal_headerTxt}`}>
              <h3>Coupon code</h3>
            </div>

            <div className={styles.cpnModal_dsktpLine}></div>

            <CouponForm
              price={props.price}
              handleCouponUpdate={props.handleCouponUpdate}
              coupon={props.coupon}
              pId={props.pId}
              serviceId={props.serviceId}
              handleSuccessMsg={props.handleSuccessMsg}
              handleRemoveAppliedCoupon={handleRemoveAppliedCoupon}
            />
            {props.couponList !== null && props.couponList.length > 0 ? (
              <div className={`${styles.coupon_bottom_sec}`}>
                <p>Available Offers</p>
                <div className={styles.cpnModal_cpnList}>
                  {props.couponList.map((item, i) => (
                    <div
                      className={
                        couponApplied && i === index
                          ? styles.coupon_list_sec_1
                          : styles.coupon_list_sec
                      }
                    >
                      <p
                        className={
                          couponApplied && i === index
                            ? styles.coupon_text_1
                            : styles.coupon_text
                        }
                      >
                        {item.couponCode}
                      </p>
                      <p
                        role="presentation"
                        onClick={() =>
                          updateCouponStatus(
                            item.couponCode,
                            item.discountedAmount,
                            i
                          )
                        }
                        className={
                          couponApplied && i === index
                            ? styles.apply_text_1
                            : styles.apply_text
                        }
                      >
                        {couponApplied && i === index ? "Applied" : "Apply"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={`mt-5 text-align-center ${styles.coupon_text}`}>
                <p>No Available offers</p>
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas show={props.showCanvasModal} placement="end">
          <Offcanvas.Header>
            <div
              onClick={props.onHideCanvasModal}
              className={styles.cpnModal_modalDesk_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div className={`mt-5 ${styles.cpnModal_headerTxt}`}>
              <h3>Coupon code</h3>
            </div>

            <div className={styles.cpnModal_dsktpLine}></div>

            <CouponForm
              price={props.price}
              handleCouponUpdate={props.handleCouponUpdate}
              coupon={props.coupon}
              pId={props.pId}
              serviceId={props.serviceId}
              handleSuccessMsg={props.handleSuccessMsg}
              handleRemoveAppliedCoupon={handleRemoveAppliedCoupon}
            />
            {props.couponList !== null && props.couponList.length > 0 ? (
              <div className={`${styles.coupon_bottom_sec}`}>
                <p>Available Offers</p>
                <div className={styles.cpnModal_cpnList}>
                  {props.couponList.map((item, i) => (
                    <div
                      className={
                        couponApplied && i === index
                          ? styles.coupon_list_sec_1
                          : styles.coupon_list_sec
                      }
                    >
                      <p
                        className={
                          couponApplied && i === index
                            ? styles.coupon_text_1
                            : styles.coupon_text
                        }
                      >
                        {item.couponCode}
                      </p>
                      <p
                        role="presentation"
                        onClick={() =>
                          updateCouponStatus(
                            item.couponCode,
                            item.discountedAmount,
                            i
                          )
                        }
                        className={
                          couponApplied && i === index
                            ? styles.apply_text_1
                            : styles.apply_text
                        }
                      >
                        {couponApplied && i === index ? "Applied" : "Apply"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={`mt-5 text-align-center ${styles.coupon_text}`}>
                <p>No Available offers</p>
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default DoctorCouponModal;
