// extracted by mini-css-extract-plugin
export var con1 = "QuestionModal-module--con1--+gs75";
export var disabledSubmitBtn = "QuestionModal-module--disabledSubmitBtn--ukhWX";
export var formControl1 = "QuestionModal-module--formControl1--8Luix";
export var labelQuestionCon = "QuestionModal-module--labelQuestionCon---Hlr3";
export var leaveTxt = "QuestionModal-module--leaveTxt--SOvxP";
export var myQuestionHeader = "QuestionModal-module--myQuestionHeader--K8hzI";
export var myQuestionModal = "QuestionModal-module--myQuestionModal--ECckZ";
export var myQuestionModalContent = "QuestionModal-module--myQuestionModalContent--C8sR+";
export var quesModal_crossIcn = "QuestionModal-module--quesModal_crossIcn--V1R3t";
export var questionModalHeader = "QuestionModal-module--questionModalHeader--LlndQ";
export var questionTitleCon = "QuestionModal-module--questionTitleCon--Pkmci";
export var submitBtn = "QuestionModal-module--submitBtn--3ajed";
export var titleStyles = "QuestionModal-module--titleStyles--ItAuZ";