import React, { useState } from "react";
import { navigate } from "gatsby";
import { Spinner } from "react-bootstrap";

// components
import QuestionModal from "../QuestionModal/QuestionModal";

// styles
import * as styles from "./DoctorDetails.module.css";

const DoctorDetails = props => {
  const [showPopUp, setShowPopUp] = useState(false);

  const handleCross = () => {
    setShowPopUp(true);
  };

  const handlePopUp = () => {
    setShowPopUp(false);
  };

  const handleShowForm = () => {
    if (props.appHeader === "mobile") {
      navigate(
        `/doctor-consult/?appHeader=${props.appHeader}&clientId=${props.clientId}`
      );
    } else if (props.appHeader === "no" && props.twinApp === "yes") {
      navigate(
        `/doctor-consult/?appHeader=${props.appHeader}&clientId=${props.clientId}&twinApp=${props.twinApp}`
      );
    } else {
      navigate("/doctor-consult/");
    }
  };

  return (
    <div>
      <div className="container twinContainer pt-4">
        {/* back navigation */}
        <div className="row">
          {(props.appHeader === "mobile" || props.appHeader === "no") &&
          props.twinApp === "yes" ? null : (
            <div className="col-12 d-flex">
              {/* back arrow icon */}
              <div
                role="presentation"
                onClick={handleCross}
                style={{
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingRight: "8px",
                }}
              >
                <span className={`icon-back`} aria-hidden="true"></span>
              </div>
              {/* text */}
              <div className={styles.docDetail_bckArrw_txt}>
                <p style={{ opacity: "1" }}>Booking consultation</p>
                <p style={{ fontSize: "12px" }}>with top diabetologists</p>
              </div>
            </div>
          )}
          {/* Desktop Line */}
          {/* <div className={`${styles.docDetail_dsktpLine_1}`}></div> */}
        </div>
        {/* doctor details */}
        {props.loading ? (
          <div className="d-block text-center">
            <Spinner animation="grow" role="status" />
          </div>
        ) : (
          <div className="row">
            {/* doctor image */}
            <div className={`col-4 col-xs-4 col-md-2 col-lg-2 mt-3`}>
              <div>
                <img
                  src={props.doctorDetail.profilePhoto}
                  alt=""
                  placeholder="blurred"
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "55px",
                  }}
                />
              </div>
              {/* doctor verified text */}
              {props.doctorDetail.verifiedTag ? (
                <div className={styles.docDetail_verifySec}>
                  <span
                    className={`mediumIcon icon-04`}
                    aria-hidden="true"
                  ></span>
                  <p>VERIFIED</p>
                </div>
              ) : null}
            </div>
            {/* doctor details */}
            <div className={`col-8 col-xs-8 col-md-10 col-lg-10 mt-3`}>
              <div className={`d-flex ${styles.docDetail_rightSec_topSec}`}>
                {/* doctor title */}
                <div className={styles.docDetail_docTitle}>
                  <h3>{props.doctorDetail.name}</h3>
                </div>

                {/* doctor designation */}
                <div className={styles.docDetail_docDsigntn}>
                  {/* <p>MBBS, MS, MSc Diabetology (UK)</p> */}
                  <p>{props.doctorDetail.designation}</p>
                </div>
              </div>
              <div
                className={`d-flex align-items-center mt-3 ${styles.docDetail_rightSec_bottomSec}`}
              >
                {/* doctor language */}
                <div className={styles.docDetail_docLan}>
                  {/* <p>Speaks English, Tamil</p> */}
                  <p>Speaks {props.doctorDetail.language}</p>
                </div>
                {/* doctor experience */}
                <div className={styles.docDetail_docExp}>
                  {/* <p>10+ years of experience</p> */}
                  <p>
                    {props.doctorDetail.experience}+{" "}
                    {props.doctorDetail.experienceDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Desktop Line */}
        <div className={`${styles.docDetail_dsktpLine_2}`}></div>
      </div>
      <QuestionModal
        handleShowForm={handleShowForm}
        show={showPopUp}
        onHide={handlePopUp}
        windowSize={props.windowSize}
      />
    </div>
  );
};

export default DoctorDetails;
