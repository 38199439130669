// extracted by mini-css-extract-plugin
export var dateTimeSec_ctaSec = "DateTime-module--dateTimeSec_ctaSec--V38o0";
export var dateTimeSec_days = "DateTime-module--dateTimeSec_days--2+ccY";
export var dateTimeSec_days_firstPara = "DateTime-module--dateTimeSec_days_firstPara--pXFkj";
export var dateTimeSec_days_notSelect = "DateTime-module--dateTimeSec_days_notSelect---EFxw";
export var dateTimeSec_days_scndPara = "DateTime-module--dateTimeSec_days_scndPara--S9qsG";
export var dateTimeSec_days_sec = "DateTime-module--dateTimeSec_days_sec--G-e7g";
export var dateTimeSec_hdng = "DateTime-module--dateTimeSec_hdng--WcKU-";
export var dateTimeSec_inpSec = "DateTime-module--dateTimeSec_inpSec--9SpDX";
export var dateTimeSec_month = "DateTime-module--dateTimeSec_month--QGPKp";
export var dateTimeSec_slotFullSec = "DateTime-module--dateTimeSec_slotFullSec--uP6Eg";