import React, { useState } from "react";
import axios from "axios";
import Offcanvas from "react-bootstrap/Offcanvas";

// config
import config from "../../../config";
//css
import * as styles from "./QuestionModal.module.css";

const payload = [
  { key: "question1", value: "I'm still browsing the page" },
  { key: "question2", value: "I didn't find the timings suitable" },
  { key: "question3", value: "I am trying to find a suitable doctor" },
  { key: "question4", value: "I didn't find the price suitable" },
];

const QuestionModal = props => {
  const [choosenQuestion, setChoosenQuestion] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleQuestionChange = e => {
    setDisabled(false);
    setChoosenQuestion(e.target.value);
  };

  const handleLeave = async () => {
    if (disabled) {
      return;
    }
    let payload = [choosenQuestion, new Date().toString()];

    const options = {
      method: "post",
      url: config.NOCODE_API_BASE_URL + "/" + config.WEBINAR_SHEET_ID,
      params: {
        tabId: "Doctor consult",
      },
      data: [payload],
    };

    try {
      await axios(options);
      props.handleShowForm();
      props.onHide();
    } catch (e) {
      console.log("error in nocodeapi calling");
      console.log(e);
      //closing modal
      props.handleShowForm();
      props.onHide();
    }
  };

  return (
    <>
      {props.windowSize && props.windowSize.width <= 768 ? (
        <Offcanvas
          show={props.show}
          placement="bottom"
          style={{ height: "50%" }}
        >
          <Offcanvas.Header className={styles.myQuestionHeader}>
            <div className={styles.questionModalHeader}>
              {" "}
              Leaving without registration for doctor consult?{" "}
            </div>
            <div
              onClick={props.onHide}
              className={styles.quesModal_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div className={styles.questionTitleCon}>
              Tell us what did not work for you
            </div>
            {payload.map((item, index) => (
              <div
                key={index}
                className={`custom-control d-flex align-items-center ${styles.formControl1}`}
              >
                <input
                  className={`custom-control-input ${styles.con1}`}
                  type="radio"
                  name="question1"
                  onChange={handleQuestionChange}
                  id={item.key}
                  checked={choosenQuestion === item.value}
                  value={item.value}
                />
                <label
                  htmlFor={item.key}
                  className={`custom-control-label ${styles.labelQuestionCon}`}
                >
                  {item.value}
                </label>
              </div>
            ))}

            <div
              role="presentation"
              onClick={handleLeave}
              className={`${
                disabled ? styles.disabledSubmitBtn : styles.submitBtn
              }`}
            >
              <p className={styles.leaveTxt}>Leave</p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas show={props.show} placement="end" style={{ height: "100%" }}>
          <Offcanvas.Header className={styles.myQuestionHeader}>
            <div className={styles.questionModalHeader}>
              {" "}
              Leaving without registration for doctor consult?{" "}
            </div>
            <div
              onClick={props.onHide}
              className={styles.quesModal_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <div className={styles.questionTitleCon}>
              Tell us what did not work for you
            </div>
            {payload.map((item, index) => (
              <div
                key={index}
                className={`custom-control d-flex align-items-center ${styles.formControl1}`}
              >
                <input
                  className={`custom-control-input ${styles.con1}`}
                  type="radio"
                  name="question1"
                  onChange={handleQuestionChange}
                  id={item.key}
                  checked={choosenQuestion === item.value}
                  value={item.value}
                />
                <label
                  htmlFor={item.key}
                  className={`custom-control-label ${styles.labelQuestionCon}`}
                >
                  {item.value}
                </label>
              </div>
            ))}

            <div
              role="presentation"
              onClick={handleLeave}
              className={`${
                disabled ? styles.disabledSubmitBtn : styles.submitBtn
              }`}
            >
              <p className={styles.leaveTxt}>Leave</p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default QuestionModal;
