// extracted by mini-css-extract-plugin
export var apply_text = "Coupon-module--apply_text--s7t5s";
export var apply_text_1 = "Coupon-module--apply_text_1--0IWfz";
export var close_btn_webinar = "Coupon-module--close_btn_webinar--OUNK8";
export var coupon_bottom_sec = "Coupon-module--coupon_bottom_sec--zi2F2";
export var coupon_enabled_sec = "Coupon-module--coupon_enabled_sec--SucUw";
export var coupon_list_sec = "Coupon-module--coupon_list_sec--ooDhb";
export var coupon_list_sec_1 = "Coupon-module--coupon_list_sec_1--69XZt";
export var coupon_text = "Coupon-module--coupon_text--Y3c30";
export var coupon_text_1 = "Coupon-module--coupon_text_1--op6M0";
export var cpnModal_cpnList = "Coupon-module--cpnModal_cpnList--TpKC9";
export var cpnModal_dsktpLine = "Coupon-module--cpnModal_dsktpLine--LLnL-";
export var cpnModal_headerTxt = "Coupon-module--cpnModal_headerTxt--esVa1";
export var cpnModal_modalDesk_crossIcn = "Coupon-module--cpnModal_modalDesk_crossIcn--PmpGm";
export var cpnModal_modalMob_crossIcn = "Coupon-module--cpnModal_modalMob_crossIcn--X-O3M";
export var cpnModal_modalMob_header = "Coupon-module--cpnModal_modalMob_header--WkpGG";