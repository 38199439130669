// extracted by mini-css-extract-plugin
export var docSched_cpnSec_hdngTxt = "doctor-consult-module--docSched_cpnSec_hdngTxt--TNXp-";
export var docSched_cpnSec_icn = "doctor-consult-module--docSched_cpnSec_icn--T2VRW";
export var docSched_cpnSec_inpBox = "doctor-consult-module--docSched_cpnSec_inpBox--BkT6U";
export var docSched_cpnSec_inpBox_chngTxt = "doctor-consult-module--docSched_cpnSec_inpBox_chngTxt--Zs7NY";
export var docSched_cpnSec_inpBox_cpnTxt = "doctor-consult-module--docSched_cpnSec_inpBox_cpnTxt--EKA9D";
export var docSched_cpnSec_inpBox_icn = "doctor-consult-module--docSched_cpnSec_inpBox_icn--YVoQ6";
export var docSched_cpnSec_ntApplySec = "doctor-consult-module--docSched_cpnSec_ntApplySec--vmCKj";
export var docSched_ctaSec = "doctor-consult-module--docSched_ctaSec--iac6G";
export var docSched_ctaSec_btn = "doctor-consult-module--docSched_ctaSec_btn--MyT6P";
export var docSched_ctaSec_btn_actPrce = "doctor-consult-module--docSched_ctaSec_btn_actPrce--YaH+1";
export var docSched_ctaSec_frstTxt = "doctor-consult-module--docSched_ctaSec_frstTxt--7EBaH";
export var docSched_dateTimeSec_bttmSec = "doctor-consult-module--docSched_dateTimeSec_bttmSec--+6qhT";
export var docSched_dateTimeSec_bttmSec_icn = "doctor-consult-module--docSched_dateTimeSec_bttmSec_icn--frkSD";
export var docSched_dateTimeSec_bttmSec_txt = "doctor-consult-module--docSched_dateTimeSec_bttmSec_txt--yY9TQ";
export var docSched_dateTimeSec_hdng = "doctor-consult-module--docSched_dateTimeSec_hdng--JqfYN";
export var docSched_dateTimeSec_subHdng = "doctor-consult-module--docSched_dateTimeSec_subHdng--7bfWb";
export var docSched_fileUpldSec = "doctor-consult-module--docSched_fileUpldSec--Pk6oG";
export var docSched_fileUpld_mobLine_1 = "doctor-consult-module--docSched_fileUpld_mobLine_1--UxTVX";
export var docSched_fileUpld_mobLine_2 = "doctor-consult-module--docSched_fileUpld_mobLine_2--O+sO1";
export var docSched_fileUpld_mobLine_3 = "doctor-consult-module--docSched_fileUpld_mobLine_3--QMqEJ";
export var docSched_schedSec = "doctor-consult-module--docSched_schedSec--3Mtf3";
export var docSched_serverError = "doctor-consult-module--docSched_serverError--IUNxF";
export var docSucc_appntmntCnt = "doctor-consult-module--docSucc_appntmntCnt--O03cp";
export var docSucc_appntmntCntSec = "doctor-consult-module--docSucc_appntmntCntSec--EdHI3";
export var docSucc_appntmntReferTxt = "doctor-consult-module--docSucc_appntmntReferTxt--6VRio";
export var docSucc_desk_line = "doctor-consult-module--docSucc_desk_line--n8XLO";
export var docSucc_desk_line_2 = "doctor-consult-module--docSucc_desk_line_2--8BqU0";
export var docSucc_referTxt_cnt = "doctor-consult-module--docSucc_referTxt_cnt--IlcoG";
export var docSucc_sccTxt = "doctor-consult-module--docSucc_sccTxt--Tb1NS";
export var docSucc_shareLnk = "doctor-consult-module--docSucc_shareLnk--zQssS";
export var docSucc_tickIcn = "doctor-consult-module--docSucc_tickIcn--knwUw";
export var docSuccss_sec = "doctor-consult-module--docSuccss_sec--JYM3c";
export var doctorConsult_schedulePage = "doctor-consult-module--doctorConsult_schedulePage--8vuAa";
export var doctorConsult_successPage = "doctor-consult-module--doctorConsult_successPage--gtfJ-";
export var twinAppBtn = "doctor-consult-module--twinAppBtn--zym29";